import React from "react";
import CanvasJSReact from '../lib/canvasjs.react';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Chart = (props) => {
  const options = {
    exportEnabled: false,
    animationEnabled: true,
    title: {
      text: "Break-up of Total Payment"
    },
    data: [{
      type: "pie",
      startAngle: 100,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: [
        { y: props.pieVal ? (100 - parseFloat(props.pieVal)) : 100, label: "Principal Loan Amount" },
        { y: props.pieVal ? parseFloat(props.pieVal) : 0, label: "Total Interest" },
        // { y: 9, label: "Paid Search" },
        // { y: 5, label: "Referral" },
        // { y: 19, label: "Social" }
      ]
    }]
  }
  return (
     <div>
        <CanvasJSChart options={options} />
     </div>
  )

}

export default Chart;