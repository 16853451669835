import React, { useEffect, useRef } from 'react'
import Forms from './Forms';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Faq from './Accordion';
import PersonalLoan from '../assets/PersonalLoan.png';
import LoanTopup from '../assets/loanopup.png';
import LoanBalanceTransfer from '../assets/loanbalancetransfer.png';
import Mobile from '../assets/whychooseus.jpg';
import HowITWorks from '../assets/working.jpg';
import EMICalulator from './Calculator';
import PieChart from '../Components/PieChart.js';
import BankLogo from '../Components/BankLogo.js';
import Testimonial from '../Components/Testimonial.js'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import BannerAnimation from '../Components/BannerText';


gsap.registerPlugin(ScrollTrigger);

const Home = () => {

  const [chartVal, setChartVal] = useState({
    val: 0,
  })

  // scroll trigger const
  const scrollTriggerOpt = {
    start: 'top center',
    end: 'bottom bottom',
    // markers: true,
  }

  // loan types animation
  const typeLoans = useRef(null);
  useEffect(() => {
    const el = typeLoans.current;
    const listEl = el.querySelectorAll('.type-loan');
    gsap.fromTo(listEl,
      {
        y: -300,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: el,
          ...scrollTriggerOpt,
          // scrub: 5,
          // toggleActions: 'restart none',
        },
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power1.out',
        stagger: 0.5,
      }
    )
  }, []);

  // why choose us animation
  const chooseUs = useRef(null);
  useEffect(() => {
    const chooseSection = chooseUs.current;
    const h2 = chooseSection.querySelector('h2.is-size-2');
    const para = chooseSection.querySelector('h2 + p');
    const listitems = chooseSection.querySelectorAll('ul li');
    gsap.fromTo(
      [h2, para],
      {
        opacity: 0,
        y: -20,
      },
      {
        scrollTrigger: {
          trigger: chooseSection,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        duration: 1,
        delay: .5,
        y: 0,
        stagger: 0.2,
        ease: 'power1.out'
      }
    );

    gsap.fromTo(
      listitems,
      {
        opacity: 0,
        y: -20,
      },
      {
        scrollTrigger: {
          trigger: chooseSection,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        y: 0,
        delay: 1,
        duration: 0.5,
        ease: 'power1.out',
        stagger: 0.2,
      }
    );
  }, []);

  // how it works animation
  const howItWork = useRef(null);
  useEffect(() => {
    const howItWorkSection = howItWork.current;
    const h2 = howItWorkSection.querySelector('h2.is-size-3');
    const serviceCards = howItWorkSection.querySelectorAll('.service-cards');
    gsap.fromTo(
      h2,
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: howItWorkSection,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        duration: 1,
        delay: .5,
        ease: 'power1.out'
      }
    );

    gsap.fromTo(
      serviceCards,
      {
        opacity: 0,
        yPercent: 100,
      },
      {
        scrollTrigger: {
          trigger: howItWorkSection,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        yPercent: -100,
        delay: 1,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
  }, []);

  // animation for loan details
  const loanDetail = useRef(null);
  useEffect(() => {
    const loanDetailSection = loanDetail.current;
    const loanDetailBoxes = loanDetailSection.querySelectorAll('.loan-detail');
    gsap.fromTo(
      loanDetailBoxes,
      {
        opacity: 0,
        yPercent: 100,
      },
      {
        scrollTrigger: {
          trigger: loanDetailSection,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        yPercent: -50,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
  }, []);

  // testimonial section animation
  const testimonialElement = useRef(null);
  useEffect(() => {
    const testimonalEle = testimonialElement.current;
    const h2 = testimonalEle.querySelector('h2.is-size-2');
    const testimonialList = testimonalEle.querySelectorAll('.type-loan');
    gsap.fromTo(
      h2,
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: testimonalEle,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        duration: 1,
        delay: .5,
        ease: 'power1.out'
      }
    );

    gsap.fromTo(
      testimonialList,
      {
        opacity: 0,
        yPercent: 100,
      },
      {
        scrollTrigger: {
          trigger: testimonalEle,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        yPercent: 0,
        delay: 1,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
  })

  // animating Accordion
  const accordionSection = useRef(null);
  useEffect(() => {
    const accordionEle = accordionSection.current;
    const firstAccordionsList = accordionEle.querySelectorAll('.columns .column:nth-child(1) .accordion .accordion__item');
    const secondAccordionsList = accordionEle.querySelectorAll('.columns .column:nth-child(2) .accordion .accordion__item');
    gsap.fromTo(
      firstAccordionsList,
      {
        opacity: 0,
        y: 50,
      },
      {
        scrollTrigger: {
          trigger: accordionEle,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        y: 0,
        delay: .5,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
    gsap.fromTo(
      secondAccordionsList,
      {
        opacity: 0,
        y: 50,
      },
      {
        scrollTrigger: {
          trigger: accordionEle,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        y: 0,
        delay: .5,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
  });

  const aboutUs = useRef(null);
  useEffect(() => {
    const aboutUsEle = aboutUs.current;
    const h2 = aboutUsEle.querySelector('h2.is-size-2');
    const p = aboutUsEle.querySelectorAll('p');
    gsap.fromTo(
      [h2, p],
      {
        opacity: 0,
        x: -100,
      },
      {
        scrollTrigger: {
          trigger: aboutUsEle,
          ...scrollTriggerOpt,
        },
        opacity: 1,
        x: 0,
        delay: .5,
        duration: 1,
        ease: 'power1.out',
        stagger: .5,
      }
    );
  })
  const navigate = useNavigate();
  const redirectToAbout = () => {
    navigate('about');
  }

  return (
    <div>
      {/* hero section start here */}
      <section className="hero">
        <div className="hero-body home">
          <div className='column'>
            <div className='columns is-align-items-center'>
              <div className='column'>
                <div className="title">
                  <BannerAnimation />
                  {/* <p className="item-2">You take the blue pill</p>

                  <p className="item-3"> I show you how deep the rabbit-hole goes.</p> */}
                </div>
              </div>
              <div className='column'>
                <Forms />
              </div>
            </div>
          </div>

        </div>
      </section >
      {/* hero section ends here */}
      {/* about us section start here */}
      <section className='about-us p-0' ref={aboutUs}>
        <div className='loanforms page-height'>
          <div className='container'>
            <div className='column '>
              <div className='columns'>
                <div className='column is-8  is-12-mobile is-align-items-center is-align-self-auto'>
                  <h2 className='has-text-weight-bold has-text-white is-size-2 mb-4'>About LoanSanthe</h2>
                  <p className='has-text-white mb-4 is-size-4'>Loansanthe is a financial consulting firm based in Bengaluru. We started in the year of 2006, with a view of providing a holistic solution for personal loans for our clients. We are a well-known personal loan provider in the city and boast over 8000 happy and satisfied customers.</p>
                  <p className="mb-6 ">
									<button className="button is-primary is-normal" onClick={redirectToAbout} style={{backgroundColor: '#005dff'}}>Read More</button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about us section ends here */}
      {/* type of loans section start here */}
      <section className='type-loans' ref={typeLoans}>
        <div className='container'>
          <h2 className='is-size-2 has-text-centered mb-3'>Types of Loan <span className='text-color'>we offer</span></h2>
          <div className='column mb-3'>
            <div className='columns'>
              <div className='column'>
                <div className='type-loan has-text-white box1'>
                  <div className='vertical-text'>
                    <div className='vertical-line-color vertical-line'>

                    </div>
                    <div className='text-rotate rotate has-text-white'>
                      Personal Loan
                    </div>
                  </div>
                  <div className='loan-icon'><img src={PersonalLoan} alt="Personal Loan" title='Personal Loan' className='img-style' /></div>
                  <div className='loan-text'> Get your personal loan sanctioned quickly</div>
                </div>
              </div>
              <div className='column'>
                <div className='type-loan has-text-white box2'>
                  <div className='vertical-text'>
                    <div className='vertical-line-color vertical-line'>

                    </div>
                    <div className='text-rotate rotate has-text-white'>
                      Loan Top-up
                    </div>
                  </div>
                  <div className='loan-icon'>
                    <img src={LoanTopup} alt="Top Up Loan" title='Top Up Loan' className='img-style' />
                  </div>
                  <div className='loan-text'>Need more capital? Get a Loan top-up</div>
                </div>
              </div>
              <div className='column '>
                <div className='type-loan has-text-white box3'>
                  <div className='vertical-text'>
                    <div className='vertical-line-color vertical-line'>

                    </div>
                    <div className='text-rotate rotate has-text-white'>
                      Loan Balance Transfer
                    </div>
                  </div>
                  <div className='loan-icon'>
                    <img src={LoanBalanceTransfer} alt="Loan Balance Transfer" title='Loan Balance Transfer' className='img-style' />
                  </div>
                  <div className='loan-text'>Transfer all your loans into one loan </div>
                </div>
              </div>
            </div>
          </div>
          <p className=' is-size-5'>We offer complete assistance in choosing the most compatible bank for your kind of loan, the ones with the lowest interest rates and the best service. We ensure you get the maximum benefits with minimal documentation as soon as possible.</p>

        </div>
      </section>
      {/* type of loans section ends here */}

      {/* Why choose us  section start here */}
      <section className='why-choose p-0 has-text-white' ref={chooseUs}>
        <div className='column'>
          <div className='columns'>
            <div className='column is-align-items-center is-align-content-center is-flex p-0'>
              <div className='why-left-col'>
                {/* <img src={Mobile} alt="Top Up Loan" title='Top Up Loan' /> */}
              </div>
            </div>
            <div className='column py-0'>
              <h2 className='is-size-2 mb-3 has-text-centered pt-3'>Why Choose Us</h2>
              <p className='mb-3'>There are thousands and thousands of loan providers, but why choose Loansanthe? Here’s why you would make a great decision by choosing us:</p>
              <ul>
                <li><span className='has-text-white is-size-5'>Customer-first approach</span>
                  <p className='has-text-white'>We put our customers first and make the loan process a breeze for them by assisting them throughout the whole process. We have over 8000 customers who will vouch for our impeccable service.</p>
                </li>
                <li><span className='has-text-white is-size-5'>Expertise:</span>
                  <p className='has-text-white'>
                    With over one and a half decades worth of serving our customers, we have gained the expertise in procuring loans for our clients and making their dreams come true.
                  </p>
                </li>
                <li>
								<span className='has-text-white is-size-5'>
                    Unique Cashback Offer
                  </span>
                  <p className='has-text-white'>
                    We assure our clients a cashback offer of 1% of their loan amount. The cashback will be credited to them along with their loan amount to their bank account.
                  </p>
                </li>
                <li>
								<span className='has-text-white is-size-5'>
                    Paperless Loan Application
                  </span>
                  <p className='has-text-white'>
                    The hustle and bustle of paper documents are done as we go with a paperless concept. Just submit all the required correct documents in digital format, and let us handle everything after that.
                  </p>
                </li>
                <li>
								<span className='has-text-white is-size-5'>
                    Transparency
                  </span>
                  <p className='has-text-white'>
                    We are transparent with our clients about the ROI, part-payments and pre-closure
                    options of all banks and NBFCs.

                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Why choose us section ends here */}

      {/* section 4  start here */}
      <section className='mission-vission'>
        <div className=''>
          <div className='column'>
            <div className='columns'>
              <div className='column'>
                <EMICalulator setChartVal={setChartVal}/>

              </div>
              <div className='column'> <PieChart pieVal={chartVal}/></div>
            </div>
          </div>
        </div>
      </section>
      {/* section 4  ends here */}
      {/* section 5 section start here */}
      <section className='how-it-works is-info' ref={howItWork}>
        <div className='container'>
          <div className='content-wrapper'>
            <div className='left-image'>
              <img src={HowITWorks} />
            </div>
            <div className='text-content'>
              <h2 className='is-size-3 heading-line'><span className='heading-text'>Why we are your best choice</span></h2>
              <div className='right-col'>
                <div className='column'>
                  <div className='columns'>
                    <div className='column'>
                      <div className='service-cards service-box1'>
                        {/* <div className='loan-icon'></div> */}
                        <h3 className='is-size-3 has-text-black'>Credit Eligibility Criteria</h3>
                        {/* <span className='card-text'>First, we will assess your criteria based on your age, profession, salary, and credit score.</span> */}
                      </div>
                    </div>
                    <div className='column'>
                      <div className='service-cards service-box2'>
                      {/* <div className='loan-icon'></div> */}
                      <h3 className='is-size-3 has-text-black'>Documents for submission</h3>
                      {/* <span className='card-text'>Once you have passed our criteria, you will be required to submit the following documents for us to process your loan:</span> */}
                     </div>
                    </div>
                    <div className='column'>
                    <div className='service-cards service-box3'>
                  {/* <div className='loan-icon'></div> */}
                  <h3 className='is-size-3 has-text-black'>Verification and Approval</h3>
                  {/* <span className='card-text'>When your application and documents pass the verification process, provided that no inconsistencies are found in them, we will be approving you for the loan sanction.</span> */}
                </div>
                    </div>
                  </div>
                </div>
              
               
               
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* section 5 ends here */}
      {/* section 6  start here */}
      <section className='loans'>
        <div className='column'>
          <div className='columns'>
            <div className='column is-hidden-mobile'>
              <div className='scale'>
                <div>

                  <div className='range-circle'> <span className='text-col  has-text-weight-bold'>6+ YEARS EXPERIENCE</span></div>
                  <div className='range-line'>
                  </div>
                </div>
                <div className='range-two'>

                  <div className='range-circle'> <span className='text-col has-text-weight-bold'>5000+ SUCCESFUL LOANS</span></div>
                  <div className='range-line'>
                  </div>
                </div>
                <div className='range-three'>

                  <div className='range-circle'>  <span className='text-col  has-text-weight-bold'>% LOW INTEREST</span></div>
                  <div className='range-line'>
                  </div>


                </div>
              </div>
            </div>
            <div className='column'>
						<h2 className='is-size-3 heading-line'><span className='heading-text'>How It Works</span></h2>
              <div className='column mt-10' ref={loanDetail}>
                <div className='columns'>
                  <div className='column'>
                    <div className='loan-detail has-text-white service-box1'>
                      <div className='vertical-text'>
                        <div className='vertical-line-color vertical-line'>

                        </div>
                        <div className='text-rotate rotate has-text-white'>
                          1
                        </div>
                      </div>
                      <div className='loan-text'>
												<h3>Credit Eligibility Criteria</h3>
											{/* <p>First, we will assess your criteria based on your age, profession, salary, and credit score.</p> */}
											</div>
                    </div>
                  </div>
                  <div className='column box-gap'>
                    <div className='loan-detail has-text-white service-box2'>
                      <div className='vertical-text'>
                        <div className='vertical-line-color vertical-line'>

                        </div>
                        <div className='text-rotate rotate has-text-white'>
                          2
                        </div>
                      </div>
                      <div className='loan-text'>
												<h3>Documents for submission</h3>
												{/* <p>Once you have passed our criteria, you will be required to submit the following documents for us to process your loan</p> */}
												</div>
                    </div>
                  </div>
                </div>
                <div className='columns'>
                  <div className='column'>
                    <div className='loan-detail has-text-white service-box3'>
                      <div className='vertical-text'>
                        <div className='vertical-line-color vertical-line'>

                        </div>
                        <div className='text-rotate rotate has-text-white'>
                          3
                        </div>
                      </div>
                      <div className='loan-text'>
												<h3>Verification and Approval</h3>
												{/* <p>When your application and documents pass the verification process, provided that no inconsistencies are found in them, we will be approving you for the loan sanction.</p> */}
											</div>
                    </div>
                  </div>
                  <div className='column box-gap'>
                    <div className='loan-detail has-text-white service-box4'>
                      <div className='vertical-text'>
                        <div className='vertical-line-color vertical-line'>
                          4
                        </div>
                        <div className='text-rotate rotate has-text-white'>
                          4
                        </div>
                      </div>
                      <div className='loan-text'>
												<h3>Loan Sanction and Disbursal</h3>
												{/* <p>Your loan amount will be sanctioned, and you will receive it in your bank account, along with our special cashback of 1% of your loan amount.</p> */}
											 </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      {/* section 6  ends here */}
      {/* section 7  start here */}
      <section className='testimonial' ref={testimonialElement}>
        <Testimonial />
      </section>
      {/* section 7  ends here */}
      {/* section 8  start here */}
      <section className='is-info accordions' ref={accordionSection}>
        <Faq />
      </section>
      {/* section 8  ends here */}
      {/* section 9  start here */}
      <section className='loans'>
        {/* <PieChart /> */}
        <BankLogo />

      </section>
      {/* section 9  ends here */}
    </div >
  )
}

export default Home;