import React, { useState } from "react";
import RadioButton from "./Radio";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { authentication } from "../firebase";
import InputTag from "./InputTag";
import axios from 'axios'
import LoadinSpinner from "./LoadingSpinner";


const Forms = () => {
  const emailRegex =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [otpVerifyStatus, setOtpVerifyStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    email: '',
    mobile: '',
    date:'',
    month:'',
    year:'',
    salary:'',
    loanAmount:'',
    company:'',
    termsAndCondition: false,
    whatsAppUpdate: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    name: '',
    email: '',
    mobile: '',
    date:'',
    month:'',
    year:'',
    salary:'',
    loanAmount:'',
    company:'',
    otp:'',
  });
  const [type, setType] = useState('Salaried Employee');
  const [showOtp, setShowOtp] = useState(false);
  const [ otp, setOtp ] = useState();
  const [success, setSucces ] = useState(false);
  const [ newOtp, setNewOtp] = useState();
  const [refresh ,setRefresh ] = useState(false);
  const [final, setFinal] = useState('');
  const setMessage = (key, msg) => {
    setErrorMsg((prev) => {
      return {...prev, [key]: msg}
    })
  }

  const validate = (field, value) => {
      if(field === 'name'){
        if(value.length < 3) {
          setMessage('name','Please enter name (3 or more characters)');
        } else {
          setMessage('name','');
        }        
      }else if(field === 'email'){
        if(!value || !value.match(emailRegex)){
          setMessage('email','Please enter a valid Email Id');
        } else {
          setMessage('email','');
        }    
      }else if (field === 'mobile') {
        if( value.length !== 10 || Number(value.slice(0, 3)) < 665){
          setMessage('mobile','Please enter a valid number');
        }else {
          setMessage('mobile','');
        }    
      }else if(field === 'loanAmount'){
        if(!value || parseInt(value) < 10000){
          setMessage('loanAmount','Minimun loan amoun must be 10000.00.')
        }else {
          setMessage('loanAmount','');
        }    
      }else if(field === 'year'){
        if(!value || (parseInt(value) + 18 > new Date().getFullYear()) || (new Date().getFullYear() - 60 > parseInt(value))){
          setMessage('year','Applicant/Co-applicant/Guarantor must be above 18 years and below 60 years of age to apply for loan.')
        }else {
          setMessage('year','');
        }    
      }
  }

  const change = (e) => {
    let {name, value } = e.target;
    validate(name,value)
    if (['termsAndCondition', 'whatsAppUpdate'].includes(name)) {
      value = !data[name];
    }
     setData((prev) => {
       return {...prev, [name]: value}
     })
   }

   const submitForm = async(event) => {
    setLoading(true);
    event.preventDefault(); 
    generateRecaptcha();
    let appVerifier = window.reCaptcha
    signInWithPhoneNumber(authentication, `+91${data.mobile}`, appVerifier).then((result) => {
      setFinal(result);
      setShowOtp(true);
      console.log('otp sent');
      setLoading(false);
    }).catch((err) => {
      alert(err);
      console.log('err', err);
      window.location.reload();
      setLoading(false);
    })
   }

   const generateRecaptcha = () => {
    window.reCaptcha = new RecaptchaVerifier('recaptcha', {
      'size': 'invisible',
      'callback': (response) => {

      }
    }, authentication);
  }


  const setEmailMessage = () => {
    // setShowOtp(false);
    setOtpVerifyStatus(true);
  }

  const validateOtp = () => {
    setLoading(true)
    // const API_PATH = 'http://localhost:3000/demo/api/index.php';
    if (otp === null || final === null)
        return;
    final.confirm(otp).then((result) => {
      console.log('otp success')
      // const API_PATH = 'http://dreamdiz.com/demo/api/index.php';
      const API_PATH = 'https://loansanthe.com/index.php';
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'multipart/form-data' },
        data: {...data, type: type}
      })
        .then(result => {
          setEmailMessage();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          setLoading(false)
        })
        .catch(error => {
          console.log('Error: ', error);
          setLoading(false)
        });
    }).catch((err) => {
      setLoading(false)
      alert("Wrong code");
    })
  }

  const otpElement = () => {
    if (showOtp && !otpVerifyStatus) {
      return (
      <div className="column centered-content">
        <div className="">
          <div className="column">
            <div className="form">
              {!success && <InputTag handleInputChange={ (e) => setOtp(e.target.value)} name={'name'} type='number' 
              value={otp}
              msg={errorMsg['otp']} 
              required>
            Enter received OTP
            </InputTag>}
                {success && <span className="content-name">
                      your Appilication has been submitted, will get back to you soon
                  </span>}
            </div>
          </div>
        </div>
        {!success && <button className="button is-warning mb-3 offer-button" onClick={validateOtp}>Submit OTP</button>}
      </div>)
    } else if (otpVerifyStatus) {
      return (
        <div className="column centered-content text-align-centered is-align-items-center">
          your Appilication has been submitted, will get back to you soon
        </div>
      )
    }
  }

  const formElement = (
    <form className="" onSubmit={submitForm}>
    <h2 className="is-size-4 has-text-centered">Select Your Profession</h2>
    <RadioButton  setValue={(e) => setType(e)} value={type}/>
    <div className="column">
      <div className="columns is-multiline is-mobile">
        <div className="column is-12">
        <InputTag handleInputChange={change} name={'name'} type='text' className="name-input"
          value={data.name.replace(/[^a-zA-Z0-9 + ?]/g, '')}
          msg={errorMsg['name']} 
          required autoFocus>
         Enter Your Name
        </InputTag>
        </div>
        <div className="column  is-12">
        <InputTag handleInputChange={change} name={'mobile'} type='text' 
          value={data.mobile.replace(/[^0-9]/g, '')} max={10} 
          msg={errorMsg['mobile']}
          required>
        Enter your Mobile Number
        </InputTag>
          {/* <span className="text-size-12">Error: Please enter valid Name</span> */}
        </div>
        <div className="column  is-12">
        <InputTag handleInputChange={change} name={'email'} type='text' value={data.email} 
          msg={errorMsg['email']}
          required>
          Enter your Email ID
        </InputTag>
        </div>
      </div>
      <div className="columns">
        <div className="column">
        <InputTag handleInputChange={change} name={'date'} type='number' value={data.date > 31 ? 31 :data.date} 
          msg={errorMsg['date']}
          max={2} required>
         Date
        </InputTag>
        </div>
        <div className="column">
        <InputTag handleInputChange={change} name={'month'} type='number' 
          value={data.month > 12 ? 12 : data.month} max={2} 
          msg={errorMsg['month']}
          required>
         Month
        </InputTag>
        </div>
        <div className="column">
        <InputTag handleInputChange={change} name={'year'} type='number' value={data.year} 
          msg={errorMsg['year']}
          max={4} required>
         Year
        </InputTag>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="columns">
        {type === 'Salaried Employee' && <div className="column">
          <InputTag handleInputChange={change} name={'company'} type='text' value={data.company} required>
        Company
        </InputTag>
        </div>}
        {type === 'Salaried Employee' && <div className="column">
        <InputTag handleInputChange={change} name={'salary'} type='text' value={data.salary} required>
        Take Home Salary
        </InputTag>

        </div>}
        <div className="column">
        <InputTag handleInputChange={change} name={'loanAmount'} type='text' 
          value={data.loanAmount.replace(/[^0-9]/g, '')} max={10} 
          msg={errorMsg['loanAmount']}
          required>
          Loan Amount Reuired
        </InputTag>
        </div>
      </div>
      <button className="button is-warning mb-3 offer-button" type="submit">Check Your Offer</button>
      <p><input type="checkbox" name="termsAndCondition" required onChange={change}/> I hereby accept all the Terms and Conditions, Privacy Policy.</p>
      <p><input type="checkbox" name="whatsAppUpdate" onChange={change}/>  I wish to receive updates on Whatsapp.</p>
    </div>
    <div id="recaptcha"></div>
  </form>
  )
  return (
    <div className="loanforms forms-section has-text-white">
      {!showOtp ? formElement : otpElement()}
      {isLoading && <LoadinSpinner></LoadinSpinner>}
    </div>
  )
}
export default Forms;