// import firebase from 'firebase/app';
// import 'firebase/compat/auth';

// const config = {
//     apiKey: "AIzaSyBof_2J_MjrK87PzmowQ8MeEbncjJTooeU",
//     authDomain: "loansanthe-f7c18.firebaseapp.com",
//     projectId: "loansanthe-f7c18",
//     storageBucket: "loansanthe-f7c18.appspot.com",
//     messagingSenderId: "343402105229",
//     appId: "1:343402105229:web:692098ed55fc6c180615c4"
// }


// firebase.initializeApp(config);

// const auth = firebase.auth();

// export { auth, firebase};

import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCvzkM9-q4q2_chzmrTkoa-dJgU7l5ryog",
    authDomain: "loansanthe-df355.firebaseapp.com",
    projectId: "loansanthe-df355",
    storageBucket: "loansanthe-df355.appspot.com",
    messagingSenderId: "694070644382",
    appId: "1:694070644382:web:e3f131a0575232dc7a758f"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);