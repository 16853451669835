import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';



const TermsConditions = () => {
  return (
    <div className="column">
      <div className="columns">

        <div className="column">

          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  1. Introduction
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Welcome to Loansanthe (“Company”, “we”, “our”, “us”)!
                </p>
                <p>These Terms and Conditions (“Terms”, “Terms of Service”) govern your access and use of our website located at <span className="has-text-weight-bold">www.loansanthe.com</span> (hereinafter referred to as “Service”), operated by <span className="has-text-weight-bold">Loansanthe</span> and maintained by <span className="has-text-weight-bold">Sravi Fin Services.</span></p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  2. Communications
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>By using our Service, you agree to subscribe to our newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any or all of these communications from us by unsubscribing or by emailing us at <span className="has-text-weight-bold">info@loansanthe.com</span>.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  3. Customer Due Diligence requirements (CDD)

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You agree and acknowledge that our Company may undertake client/customer due diligence measures and seek mandatory information required for KYC purposes which, as a customer, you are obliged to give while facilitating your request for loan and other financial product requirements with the banks/financial institutions, in accordance with applicable Prevention of Money Laundering Act (“PMLA”) and rules. Our Company may obtain sufficient information to establish, to its satisfaction or the banks/financial institutions, the identity of each new customer/user and to ascertain the purpose of the intended nature of the relationship between you and the bank/financial institution. You agree and acknowledge that our Company can undertake enhanced due diligence measures (including any documentation), to satisfy itself relating to customer due diligence requirements in line with the requirements and obligations under the applicable PMLA Act and rules.
                </p>
                <p>
                  You represent and warrant that the information you supply to us is true, correct, and complete. By submitting your information, you grant us the right to provide the information to the banks/financial institutions subject to our Privacy Policy.
                </p>
                <p>
                  We reserve the right to refuse or cancel your loan request at any time for reasons including but not limited to service availability, error in your information or other reasons.
                </p>
                <p>
                  We reserve the right to refuse or cancel your request if any fraudulent or unauthorized or illegal activity is suspected.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  We use your information in order to:
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul>
                  <li>Facilitate and deliver services to you, process payments and your applications, and communicate with you about products, services, and promotional offers.
                  </li>
                  <li>Respond to queries or requests submitted by you, and resolve your grievances/issues/problems with any services supplied to you.
                  </li>
                  <li>Send you information about special promotions or offers. We might also tell you about new features or products/services. These might be our own offers/promotions or services.
                  </li>
                  <li>Use your information for internal analysis and to provide you with location-based services, such as advertising, search results, and other personalized content.
                  </li> <li>Use this information to improve our platform, prevent or detect fraud or abuses of our Website and enable third parties to carry out technical, logistical or other functions on our behalf. We may combine information we get from you with information about you we get from third parties.
                  </li> <li>Send you notices communications, and recommend services that might be of interest to you, update our records, and generally maintain your accounts with us, display content and customer reviews.
                  </li><li>As otherwise provided in this Privacy Policy.
                  </li>
                </ul>
                <p>Some features of this Website or our Services will require you to furnish your personally identifiable information as provided by you under your account section on our Website.
                </p>
                <p>You also agree and consent to us collecting, storing, processing, transferring and sharing information (including sensitive personal information) related to you with third parties or service providers for the purposes as set out in this Privacy Policy.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  4. No Use By Minors
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the Terms and Conditions. If you are not at least eighteen (18) years old, you are prohibited from both access and usage of our Service.
                </p>

              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  5. Contests and Promotions

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Any contests or other promotions (collectively, “Promotions”) made available through our  Service may be governed by rules that are separate from these Terms and Conditions. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms and Conditions, Promotion rules will apply.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  6. Content
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The content found on or through this Service is the property of Loansanthe, maintained by Sravi Fin Services or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  7. Prohibited Uses
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You may use the Service only for lawful purposes and in accordance with the Terms. You agree not to use Service:
                </p>
                <p>0.1. In any way that violates any applicable national or international law or regulation.
                </p>
                <p>
                  0.2. To transmit or procure the sending of any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                </p>
                <p>
                  0.3. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
                </p>
                <p>
                  0.4. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                </p>
                <p>
                  0.5. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend the Company or users of Service or expose them to liability.
                </p>
                <p className="has-text-weight-bold">Additionally, you agree not to:</p>
                <p>
                  0.1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real-time activities through Service.
                </p>
                <p>
                  0.2. Use any robot, spider, or another automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.

                </p>
                <p>
                  0.3. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.

                </p>
                <p>
                  0.4. Use any device, software, or routine that interferes with the proper working of the Service.
                </p>
                <p>
                  0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
                </p>
                <p>
                  0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
                </p>
                <p>
                  0.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                </p>
                <p>
                  0.8. Take any action that may damage or falsify Company ratings.
                </p>
                <p>
                  0.9. Otherwise, attempt to interfere with the proper working of Service.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  8. Intellectual Property
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Loansanthe, maintained by Sravi Fin Services and its licensors. Service is protected by copyright, trademark, and other laws of foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Loansanthe.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  9. Copyright Policy
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.
                </p>
                <p>
                  If you are a copyright owner or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@loansanthe.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”.
                </p>
                <p>
                  You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  10.  DMCA Notice and Procedure for Copyright Infringement Claims
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                </p>
                <p>
                  0.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;
                </p>
                <p>
                  0.2. a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
                </p>
                <p>
                  0.3. identification of the URL or other specific location on the Service where the material that you claim is infringing is located;
                </p>
                <p>
                  0.4. your address, telephone number, and email address;
                </p>
                <p>
                  0.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
                </p>
                <p>
                  0.6. a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                </p>
                <p>You can contact us via email at <span className="has-text-weight-bold"></span>info@loansanthe.com.</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  11. Error Reporting and Feedback
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>You may provide us directly at info@loansanthe.com with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title, or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  12. Limitation Of Liability
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER, IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  13. Termination

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We may terminate or suspend your account and bar access to our Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms and Conditions.
                </p>
                <p>
                  If you wish to terminate your account, you may simply discontinue using our Service.
                </p>
                <p>
                  All provisions of Terms and Conditions, which by their nature should survive termination, shall survive termination, including without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  14. Governing Law
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  These Terms and Conditions shall be governed and construed in accordance with the laws of India, which governing law applies to the agreement without regard to its conflict of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms and Conditions is held to be invalid or unenforceable by a court, the remaining provisions of these Terms and Conditions will remain in effect. These Terms and Conditions constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding our Service.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  15. Changes To Service
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We reserve the right to withdraw or amend our Service and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of our Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of our Service or the entire Service to users, including registered users.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  16. Amendments To Terms
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We may amend the Terms and Conditions at any time by posting the amended version on this site. It is your responsibility to review these Terms and Conditions periodically.
                </p>
                <p>
                  Your continued use of our Service following the posting of revised Terms and Conditions means that you accept and agree to the changes. You are expected to check this page frequently, so you are aware of any changes, as they are binding on you.
                </p>
                <p>
                  By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use our Service.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  17. Waiver And Severability
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  No waiver by the Company of any term outlined in Terms and Conditions shall be deemed a further or continuing waiver of such term or condition, or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under Terms and Conditions shall not constitute a waiver of such right or provision.
                </p>
                <p>
                  If any provision of Terms and Conditions is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms and Conditions will continue in full force and effect.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  18. Acknowledgement
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  BY USING THIS SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND CONDITIONS AND AGREE TO BE BOUND BY THEM.
                </p>
              </AccordionItemPanel>
            </AccordionItem>


          </Accordion>
        </div>

      </div>
    </div>


  )
}

export default TermsConditions;