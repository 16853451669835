import React, { useEffect, useRef, useState } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import axios from 'axios';
import LoadinSpinner from "./LoadingSpinner";


gsap.registerPlugin(ScrollTrigger);

const ContactUs = () => {

    // scroll trigger const
    const scrollTriggerOpt = {
      start: 'top center',
      end: 'bottom bottom',
      // markers: true,
    }
  
    // contact banner animation
    const contactBanner = useRef(null);
    useEffect(() => {
      const el = contactBanner.current;
      const listEl = el.querySelectorAll('.contact-banner-content');
      gsap.fromTo(listEl,
        {
          x: -100,
          opacity: 0,
        },
        {
          scrollTrigger: {
            trigger: el,
            ...scrollTriggerOpt,
            // scrub: 5,
            // toggleActions: 'restart none',
          },
          opacity: 1,
          x: 0,
          duration: 1,
          ease: 'power1.out',
          stagger: 0.3,
        }
      )
    }, []);

     // contact info animation
     const contactInfo = useRef(null);
     useEffect(() => {
       const el = contactInfo.current;
       const listEl = el.querySelectorAll('.contact-info');
       gsap.fromTo(listEl,
         {
           y: -100,
           opacity: 0,
         },
         {
           scrollTrigger: {
             trigger: el,
             ...scrollTriggerOpt,
             // scrub: 5,
             // toggleActions: 'restart none',
           },
           opacity: 1,
           y: 0,
           duration: 1,
           ease: 'power1.out',
           stagger: 0.3,
         }
       )
     }, []);

     // contact Box animation
     const contactBox = useRef(null);
     useEffect(() => {
       const el = contactBox.current;
       const listEl = el.querySelectorAll('.value-box');
       gsap.fromTo(listEl,
         {
           y: 100,
           opacity: 0,
         },
         {
           scrollTrigger: {
             trigger: el,
             ...scrollTriggerOpt,
             // scrub: 5,
             // toggleActions: 'restart none',
           },
           opacity: 1,
           y: 0,
           duration: 1,
           ease: 'power1.out',
           stagger: 0.3,
         }
       )
     }, []);

  const [data, setData] = useState({
    name: '',
    email: '',
    mobile: '',
    service: '',
    message: '',
  });

  const [formSubmited, setFormSubmited] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const change = (e) => {
    const { name, value } = e.target;
    // validate(name,value)
    setData((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    const API_PATH = 'https://loansanthe.com/contact.php';
    // const API_PATH = '/api/contact.php';
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'multipart/form-data' },
        data: {...data}
      })
        .then(result => {
          console.log('???????????', result)
          setFormSubmited(true);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch(error => console.log(error))
        .finally(() => {
          console.log('finally triggered')
          setLoading(false);
        });
  }

  return (
    <div>
      <section className='contact-banner'>
        <div className='column'>
          <div className='columns'>
            <div className='column' ref={contactBanner}>
              <div className='is-uppercase has-text-weight-bold mb-3 has-text-white contact-banner-content'>Contact us</div>
              <h1 className='mb-3 has-text-white contact-banner-content'>
                Get in touch
              </h1>
              <div className='mb-3 is-size-5 has-text-grey-light contact-banner-content'>
              If you are looking for Personal loans, you have landed on the right page.
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/LoanSanthe/"  title="Loan Santhe Facebook" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-f contact-banner-content fa-2x"></i>
                </a>
                <a href="https://twitter.com/LoanSanthe" title="Loan Santhe Twitter" target="_blank" className="ml-5" rel="noreferrer">
                  <i className="fab fa-twitter contact-banner-content fa-2x"></i>
                </a>
                {/* <a href="" title="Youtube" className="ml-5" target="_blank" >
                  <i className="fab fa-youtube contact-banner-content fa-2x"></i>
                </a> */}
                <a href="https://www.linkedin.com/in/personal-loan-bangalore-173470142/" target="_blank" title="Loan Santhe  LinkedIn" className="ml-5" rel="noreferrer">
                  <i className="fab fa-linkedin-in contact-banner-content fa-2x"></i>
                </a>
              </div>

            </div>
            <div className='column pl-6 has-text-white is-relative'>
              {!formSubmited ? <form className="contact-form" onSubmit={submitForm}>
                <div className="column">
                  <div className="columns is-multiline is-mobile">
                    <div className="column is-6">
                      <label className='label has-text-white is-size-5'>Name</label>
                      <input className='input' placeholder='Enter your name' name='name' type="text" onChange={change} value={data.name.replace(/[^a-zA-Z0-9 + ?]/g, '')} required></input>
                    </div>
                    <div className="column  is-6">
                      <label className='label has-text-white is-size-5'>Email address</label>
                      <input className='input' placeholder='Enter your email' name='email' type="text" onChange={change} value={data.email} required></input>
                    </div>
                    <div className="column  is-6">
                      <label className='label has-text-white is-size-5'>Phone number</label>
                      <input className='input' placeholder='Enter phone number' name='mobile' type="text" value={data.mobile.replace(/[^0-9]/g, '')} max={10} onChange={change} required></input>
                    </div>
                    <div className="column is-6">
                      <label className='label has-text-white is-size-5'>Service</label>
                      <input className='input' placeholder='Enter Service' name='service' type="text" onChange={change} value={data.service} required></input>
                    </div>
                    <div className="column is-12 textarea-field">
                    <label className='label has-text-white is-size-5'>Message</label>
                      <textarea placeholder='Enter the message'
                        name="message" id="" cols="30" rows="10" className='textarea' onChange={change} value={data.message} />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <button className="button is-warning mb-3 offer-button" type="submit">Send message</button>
                </div>
              </form> :
                <div className="column centered-content text-align-centered is-align-items-center">
                Form has been submitted, will get back to you soon.
              </div>}
              {isLoading && <LoadinSpinner></LoadinSpinner>}
            </div>
          </div>
        </div>
      </section>
      <section className='contact-info'>
        <div className='is-flex is-align-items-center is-flex-direction-column' ref={contactInfo}>
          <div className='is-uppercase has-text-weight-bold mb-3 has-text-grey-dark contact-info'>Get in touch</div>
          <div className='contact-info-title mb-3 contact-info'>
            Prefer to reach out directly?
          </div>
          <div className="contact-info-text is-size-5 mb-4 contact-info">
          If you are looking for Personal loans, you have landed on the right page
          </div>
        </div>
        <div className="column contact-info-box" ref={contactBox}>
          <div className="columns">
            <div className="column p-0">
              <div className="value-box box1">
                <div className="is-size-5 has-text-white has-text-weight-semibold mb-2">
                  <i className="far fa-envelope fa-2x"></i>
                </div>
                <div className='is-size-3 has-text-weight-bold mb-3 has-text-white'>Send us an email</div>
                <div className='is-size-5 has-text-white-ter mb-3'>
                Drop an email with your query/request, we will get back to you as quickly as we can.
                </div>
                <div className='is-size-5 has-text-white-ter mb-3'>
                info@loansanthe.com
                </div>
              </div>
            </div>
            <div className="column p-0">
              <div className="value-box box2">
                <div className="is-size-5 has-text-white has-text-weight-semibold mb-2">
                  <i className="fa fa-phone-alt fa-2x"></i>
                </div>
                <div className='is-size-3 has-text-weight-bold mb-3 has-text-white'>Give us a call</div>
                <div className='is-size-5 has-text-white-ter mb-3'>
                  We are just one call away, call us with your query/request and we will guide you through them.
                </div>
                <div className='is-size-5 has-text-white-ter mb-3'>
                <a href="tel:9035378777" className="has-text-white">+91 90353 78777</a>
                </div>
              </div>
            </div>
            <div className="column p-0">
              <div className="value-box box3">
                <div className="is-size-5 has-text-white has-text-weight-semibold mb-2">
                  <i className="fa fa-globe fa-2x"></i>
                </div>
                <div className='is-size-3 has-text-weight-bold mb-3 has-text-white'>Visit our office</div>
                <div className='is-size-5 has-text-white-ter mb-2'>
                  Drop by anytime anyday during our open hours, we are there to guide you in-person and offer our best services.
                </div>
                <div className='is-size-5 has-text-white-ter mb-3'>
                  <p>No: 13/2, 2nd floor,</p>
                  <p>1st Cross, CKC garden, lal Bagh road,</p>
                  <p>near air Craft Union Association,</p>
                  <p>bangalore, Karnataka - 560027</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs;