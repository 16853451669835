export const scrollToFunc = (val, focus) => {
  const btn = document.querySelector('.btn-top');
  const icon = btn.querySelector('i');
  if (val == 'trigger') {
    if (Array.from(icon.classList).includes('fa-rotate-180')) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      btn.classList.add('is-hidden')
    } else {
      window.scrollTo({
        top: document.body.offsetHeight,
        behavior: 'smooth',
      });
    }
    if (focus) {
      const inputName = document.querySelector('.name-input');
      if (inputName) {
        inputName.focus();
      }
    }
  }
  if (window.pageYOffset < 3 && !(Array.from(btn.classList).includes('is-hidden'))) {
    btn.classList.add('is-hidden')
  } else if (window.pageYOffset > 3) {
    btn.classList.remove('is-hidden')
  }

  if ((window.innerHeight + parseInt(window.pageYOffset)) < document.body.offsetHeight) {
    icon.classList.add('fa-rotate-180');
  }
}