import React from 'react';
import MainLogo from '../assets/loanSanthe-logo.png';
import { NavLink } from "react-router-dom";

const Navibar = () => {

  const menuClicked = () => {
    console.log('clicked');
    const navBurger = document.querySelector('.navbar-burger');
    const navMenu = document.querySelector('.navbar-menu');
    navBurger.classList.toggle("is-active")
    navMenu.classList.toggle("is-active");
  }

  return (

    <nav className='navbar is-fixed-top' role='navigation' aria-label='main navigation'>
      <div className="container">
        <div className='navbar-brand'>
          <a href='/' className='navbar-item'>
          <img src={MainLogo} alt="loanSanthe" title='loanSanthe' />
            {/* <img
              src='https://bulma.io/images/bulma-logo.png'
              alt='Logo'
              width='112'
              height='28'
            /> */}
          </a>

          <a
            role='button'
            className={'navbar-burger burger'}
            aria-label='menu'
            aria-expanded='false'
            data-target='navbarBasicExample'
            onClick={menuClicked}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div id='navbarBasicExample' className='navbar-menu'>
          <div className='navbar-end'>
            <div className='navbar-item'>
            <NavLink to="/" className='navbar-item' style={({isActive}) => ({color: isActive ? '#0567a9' : 'black'})}>Home</NavLink>
            <NavLink to="/about" className='navbar-item' style={({isActive}) => ({color: isActive ? '#0567a9' : 'black'})}>About Us</NavLink>
              {/* <a href='/' className='navbar-item'>
                Personal Loan
              </a> */}
            <NavLink to="/contact" className='navbar-item' style={({isActive}) => ({color: isActive ? '#0567a9' : 'black'})}>Contact Us</NavLink>
              {/* <a href='/' className='navbar-item'>
                Contact us
              </a> */}
            </div>
          </div>
        </div>
        {/* <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-primary">
                <strong>Sign up</strong>
              </a>
             
            </div>
          </div>
        </div> */}
      </div>
    </nav>
  )
}

export default Navibar;