import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
  return (
    <div className="column">
      <h1 className="is-size-3 mb-2">FAQ's</h1>
      <div className="columns">
        <div className="column">
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold">1. What is a personal loan? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  A personal loan is the type of loan that can be taken for any personal purpose such as home renovations, medical expenses, education, wedding, or any family celebration, and even for vacation or travel.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold"> 2. How will the loan eligibility criteria be determined?</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  The eligibility will be determined by the applicant’s age, earning capacity, professional stability, credit score, health condition, ability to repay the loan, etc.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold">3. What is a credit score? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  Anyone who has taken a loan or uses a credit card will have a trail of their repayment history. In India, CIBIL and similar credit bureaus keep track of this. The credit score plays a huge role in determining the loan eligibility of a person, as it shows whether the person has repaid their dues on time. So when someone has a high credit score, they have better chances of getting their loan approved and sanctioned.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold"> 4. Are personal loans tax-exempt? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  Only personal loans that have been taken for the purpose of home renovations or to make a down payment for purchasing a home are eligible for tax exemption.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="column">
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold">5. Are collaterals or security required to take a personal loan? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  No, there is no requirement for any collateral or security for taking a personal loan through Loansanthe.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold"> 6. How to repay the personal loan taken through Loansanthe? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  You can repay your loan by way of salary deduction, post-dated cheques or ECS(Electronic Clearing Service).
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold">7. What is the Loansanthe 1% Cashback offer? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  When you take a personal loan with Loansanthe, we offer you a cashback of 1% of your loan amount. This amount will be credited to your bank account along with the loan amount disbursed.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="has-text-weight-bold">8. What is the maximum loan repayment period? </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="has-text-black">
                  The maximum time period provided for your loan repayment is 2 years.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Faq;