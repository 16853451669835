import './App.css';
import 'bulma/css/bulma.css';
import './css/Main.css';
import Footer from './Components/Footer';
import Navibar from './Components/Navibar';
import Home from './Components/Home';
import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import { scrollToFunc } from './utils';


function App() {

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      scrollToFunc();
    })
    window.addEventListener('load', () => {
      scrollToFunc();
    })
  }, []);
  return (
    <div>
      <Navibar />
      <Outlet />
      <Footer/>
      <div className="btn-top is-flex is-align-items-center is-justify-content-center" onClick={() => scrollToFunc('trigger')}>
        <i className="fas fa-angle-down fa-2x"></i>
      </div>
    </div>
    // <div className="App">
    //   <h1 className='is-size-1 has-background-black has-text-white'>welcome to my page</h1>
    // </div>
    

  );
}

export default App;
