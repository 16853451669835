import React, { useEffect, useState } from "react";
import { RadioGroup, Radio } from 'react-radio-group';

const RadioButton = (props) => {
  const [typeLoan, setTypeLoan] = useState(props.value)

  useEffect(() => {
  }, [typeLoan])
  return (
    <div className="is-flex is-align-items-center">
      <RadioGroup onChange={(e) => {setTypeLoan(e); props.setValue(e)}} selectedValue={typeLoan}>
        <label className="mr-15"><Radio value="Salaried Employee" type="Loan"  />Salaried Employee</label>      
        <label className="mr-15"><Radio value="Doctor" type="Loan" />Doctor</label>
      </RadioGroup> 
    </div>
  )
}

export default RadioButton;