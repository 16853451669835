import React, { useEffect } from "react";
import { useState } from "react";


const EMICalulator = (props) => {

  const [calculatorObj, setCalcValue] = useState({
    loanAmount: 100000,
    intrestRate: 9,
    loanTenure: 18,
    tenureType: 'year',
  })

  const [emiObj, setEmiValue] = useState({
    loanEmi: 0,
    totalIntrest: 0,
    totalPay: 0,
  })

  useEffect(() => {
    const convertedTenure = calculatorObj.tenureType === 'year' ? calculatorObj.loanTenure * 12 : calculatorObj.loanTenure;
    const roi = (calculatorObj.intrestRate/12/100);
    const numOfCycle = Math.pow((1 + roi), convertedTenure)
    const emi = calculatorObj.loanAmount * roi * ( numOfCycle/ (numOfCycle - 1));
    const tempObj = {
      loanEmi: Math.ceil(emi),
      totalIntrest: Math.ceil((emi * convertedTenure) - calculatorObj.loanAmount),
      totalPay: Math.ceil((emi * convertedTenure)),
    }

    /* 
      r = intrest / 12 / 100;
      emi = p * r * (((1+r)^tenure) / (((1+r)^tenure)-1))
    */
    setEmiValue({...tempObj})

    const pieVal = parseFloat((tempObj.totalIntrest/ tempObj.totalPay) * 100).toFixed(1);

    props.setChartVal(pieVal !== 'NaN' ? pieVal : 0);
  }, [calculatorObj.loanAmount, calculatorObj.intrestRate, calculatorObj.loanTenure, calculatorObj.tenureType]);


  const changeVal = (key, val) => {
    const temp = {...calculatorObj}
    temp[key] = ['intrestRate', 'tenureType'].includes(key) ? val : parseInt(val);
    if (key === 'tenureType') {
      temp.loanTenure = temp.tenureType === 'month' ? calculatorObj.loanTenure * 12 : calculatorObj.loanTenure / 12;
    }
    setCalcValue({...temp});
  }

  return (
    <div className="container pr-0 pl-0">
      <div className="column">
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header is-primary">
                <p className="card-header-title">
                  EMI Calulator
                </p>
              </header>
              <div className="card-content p-0">
                <div className="content">
                  <div className="field">
                    <span className="is-size-6 has-text-weight-semibold">Loan Amout</span>
                    <p className="control has-icons-left has-icons-right">
                      <input className="input" type="number" placeholder="Loan Amout" value={calculatorObj.loanAmount} onChange={(e) => changeVal('loanAmount', e.target.value)}/>
                      <span className="icon is-small is-left">
                        <i className="is-size-4" >₹</i>
                      </span>

                    </p>
                  </div>
                  <div className="field">
                    <span className="is-size-6 has-text-weight-semibold">Interest Rate</span>
                    <p className="control has-icons-left">
                      <input className="input" type="number" placeholder="Interest Rate" value={calculatorObj.intrestRate} onChange={(e) => changeVal('intrestRate', e.target.value)}/>
                      <span className="icon is-small is-left">
                        <i className="is-size-4" >%</i>
                      </span>
                    </p>
                  </div>

                  <div className="field">
                    <span className="is-size-6 has-text-weight-semibold">Loan Tenure</span>
                    <p className="control has-icons-left">
                      <input className="input" type="number" placeholder="Loan Tenure" value={calculatorObj.loanTenure} onChange={(e) => changeVal('loanTenure', e.target.value)}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </p>
                  </div>

                  <div className="buttons">
                    <button className="button is-info" disabled={calculatorObj.tenureType === 'month'} onClick={() => {changeVal('tenureType', 'month');}}>Month</button>
                    <button className="button is-success" disabled={calculatorObj.tenureType === 'year'} onClick={() => {changeVal('tenureType', 'year');}}>Year</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="column total-count">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">

                </p>
              </header>
              <div className="card-content">
                <div className="emi-total-view">
                  <h4>Loan EMI</h4>
                  <h4 className="has-text-weight-bold">₹ {emiObj.loanEmi || 0}</h4>
                </div>
                <div className="emi-total-view">
                  <h4>Total Interest Payable
                  </h4>
                  <h4 className="has-text-weight-bold">₹ {Math.ceil(emiObj.totalIntrest || 0)}</h4>
                </div>
                <div className="emi-total-view">
                  <h4>Total of Payments
                    (Principal + Interest)</h4>
                  <h4 className="has-text-weight-bold">₹ {Math.ceil(emiObj.totalPay || 0)}</h4>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default EMICalulator;