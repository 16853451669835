import React from "react";
import axis from '../assets/axisBank.jpg';
import Hdfc from "../assets/hdfc-bank.jpg"
import Icici from "../assets/icici-bank.jpg"
import Idfc from "../assets/idfc-first-bank.jpg"
import Yes from "../assets/yes-bank.jpg"

const LoanProvider = () => {
  return (
    <div className='column '>
      <div className='columns'>
        {/* <div className='column'>
        <img src={Axis} alt=" Axis " title='' />
        </div> */}
        <div className='column is-flex is-align-items-center is-justify-content-center'>  <img src={axis} /></div>
        <div className='column is-flex is-align-items-center is-justify-content-center'>  <img src={Hdfc} /></div>
        <div className='column is-flex is-align-items-center is-justify-content-center'>  <img src={Icici} /></div>
        <div className='column is-flex is-align-items-center is-justify-content-center'>  <img src={Idfc} /></div>
        <div className='column is-flex is-align-items-center is-justify-content-center'>  <img src={Yes} /></div>
      </div>
    </div>
  )
}

export default LoanProvider;