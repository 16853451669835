import React, { useState, useEffect } from 'react';
const textLoop = [

  "Follow your personal loan dreams with us",
  "Gain more with our unique 1% cashback offer*",
  "Need your loan in a flash? Paperless* process to get the loan quickly to you."
];

const BannerAnimation = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // console.log('textLoop[index].length * 200', textLoop[index].length * 200);
    const intervalDelayMilliseconds = 7000;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < textLoop.length ? prevIndex + 1 : 0;
      });
    }, intervalDelayMilliseconds);
    const interval2 = setInterval(() => {
      setIndex((prevIndex) => {
        const ele = document.querySelector(`.text-slider${prevIndex+1}`);
        ele.classList.add('fade-out');
        // // reset index if current index is greater than array size
        return prevIndex;
      });
    }, 6100);

    return () => {clearInterval(interval2);clearInterval(interval);};
  });

  const getBanerIndexContent = (index) => {
    switch (index) {
      case 0: return (<h1 className='is-size-1 has-text-centered text-slider1'>
          Follow Your <br></br><span className='text-color'>Personal Loan</span><br></br> dreams with us.
        </h1>);
      case 1: return (<h1 className='is-size-1 has-text-centered text-slider2'>
          Gain more with our <br></br><span className='text-color'>unique 1%</span><br></br> cashback offer.
        </h1>);
      case 2: return (<h1 className='is-size-1 has-text-centered text-slider3'>
          Need your loan in a flash? <br></br><span className='text-color'>Paperless  process</span><br></br> to get the loan quickly to you.
        </h1>);
      default:  return (<h1 className='is-size-1 has-text-centered text-slider1'>
          Follow Your <br></br><span className='text-color'>Personal Loan</span><br></br> dreams with us.
        </h1>);
    }
  }

  return (
    <div>
      {getBanerIndexContent(index)}
    </div>
  );
}

export default BannerAnimation;