import React from "react";

const InputTag = (props) => {
  
 
  return (
   <>
     <div className="form">
      <input
        value={props.value}
        onChange={(e) =>{
           props.handleInputChange(e)}}
        className={"input " + (props.className ? props.className : '')}
        type={props.type}
        required={props.required || false}
        name={props.name}
        maxLength={props.max || 35}
        autoFocus={props.autoFocus ? true : false}
      />
      <label for="text" className="label-name">
        <span className="content-name">
          {props.children}
        </span>
      </label>
    </div>
    {props.msg && <span className="text-size-12">{props.msg}</span>}
   </>

  )
}

export default InputTag;