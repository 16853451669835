import React from "react";
import Photo from "../assets/photo.png"
const FeedBack = () => {
  return (
    <div className='container testimonial-container'>
      <h2 className="has-text-centered is-size-2">Testimonial</h2>
      <div className='column'>
        <div className='columns'>
          <div className='column'>
            <div className='type-loan t has-text-white'>
              <div className='vertical-text'>
                <div className='vertical-line-color vertical-line'>
                  4
                </div>
                <div className='text-rotate rotate has-text-white'>

                </div>
              </div>
              <div className='loan-text'>
              I have recently availed a personal loan through loansanthe.com. My loan was sanctioned by Yes Bank and I am fully satisfied with the services by the Loansanthe team. I will refer my colleagues to take up personal loans from loansanthe.com. I even got 1% cash back money.
              </div>
              <div className="is-flex is-align-items-center is-align-content-end">
                <div className="author">
                  <img src={Photo} alt="Photo" title='Photo' />
                </div>
                <span className="is-size-6 has-text-weight-bold has-text-black">Harish</span>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='type-loan has-text-white'>
              <div className='vertical-text'>
                <div className='vertical-line-color vertical-line'>
                  4
                </div>
                <div className='text-rotate rotate has-text-white'>

                </div>
              </div>
              <div className='loan-text'>
              Hi. myself Syed. I am working in HP Network. Recently I took loan of 25 Lakhs from ICICI through loansanthe.com. I got an offer of 10.25% ROI, lowest processing fee and an additional 1% cash back.
              </div>
              <div className="is-flex is-align-items-center is-align-content-end">
                <div className="author">
                  <img src={Photo} alt="Photo" title='Photo' />
                </div>
                <span className="is-size-6 has-text-weight-bold has-text-black">Syed</span>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='type-loan has-text-white'>
              <div className='vertical-text'>
                <div className='vertical-line-color vertical-line'>
                  4
                </div>
                <div className='text-rotate rotate has-text-white'>

                </div>
              </div>
              <div className='loan-text'>
              Deepak here, working at Mccafe. I had recently taken loan through loansanthe.com for 48 Lakhs from HDFC Bank at an interest rate of 10.25%, processing fee of just 3,000 and an additional 1% cash back. I find it so worthy for me and I am getting good service from the Loansanthe team.
              </div>
              <div className="is-flex is-align-items-center is-align-content-end">
                <div className="author">
                  <img src={Photo} alt="Photo" title='Photo' />
                </div>
                <span className="is-size-6 has-text-weight-bold has-text-black">Deepak</span>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-offset-2 is-4'>
            <div className='type-loan has-text-white'>
              <div className='vertical-text'>
                <div className='vertical-line-color vertical-line'>
                  4
                </div>
                <div className='text-rotate rotate has-text-white'>

                </div>
              </div>
              <div className='loan-text'>
              Recently I got loan from ICICI Bank of 29Lakhs, at the best ROI of 10.25% and 1% cash back also I received. I am working in Qualcomm and myself Shiva Kumar Gopal. I made all loan balance transfer and made into one loan. loansanthe.com is giving amazing service to me and guiding me very well.
              </div>
              <div className="is-flex is-align-items-center is-align-content-end">
                <div className="author">
                  <img src={Photo} alt="Photo" title='Photo' />
                </div>
                <span className="is-size-6 has-text-weight-bold has-text-black">Shiva Kumar gopal</span>
              </div>
            </div>
          </div>
          <div className='column  is-4'>
            <div className='type-loan has-text-white'>
              <div className='vertical-text'>
                <div className='vertical-line-color vertical-line'>
                  4
                </div>
                <div className='text-rotate rotate has-text-white'>

                </div>
              </div>
              <div className='loan-text'>
              This is Naveen from Standard Chartered Bank. I am also working in the loan department only. When I was in need of a loan I tried so much, but it was not possible to get. But I got to know about loansanthe.com and he guided me very well and they merged all my financial obligations to 1 personal loan. I got loan of 18 Lakhs, ROI, lesser processing fee and additionally I got 1% cash back also.
              </div>
              <div className="is-flex is-align-items-center is-align-content-end">
                <div className="author">
                  <img src={Photo} alt="Photo" title='Photo' />
                </div>
                <span className="is-size-6 has-text-weight-bold has-text-black">Naveen</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default FeedBack;