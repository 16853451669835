import React, { useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import aboutUsBanner from '../assets/aboutus.jpg'
import missionImage from '../assets/mission.jpg'
import Mission from '../assets/waiter.jpg';
import Vission from '../assets/mobile.jpg';
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/');
  }

  // scroll trigger const
  const scrollTriggerOpt = {
    start: 'top center',
    end: 'bottom bottom',
    // markers: true,
  }

  // about banner animation
  const aboutBanner = useRef(null);
  useEffect(() => {
    const el = aboutBanner.current;
    const listEl = el.querySelectorAll('.about-banner-content');
    gsap.fromTo(listEl,
      {
        x: 100,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: el,
          ...scrollTriggerOpt,
          // scrub: 5,
          // toggleActions: 'restart none',
        },
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power1.out',
        stagger: 0.5,
      }
    )
  }, []);

   // about mission animation
   const aboutMission = useRef(null);
   useEffect(() => {
     const el = aboutMission.current;
     const listEl = el.querySelectorAll('.mission-content');
     gsap.fromTo(listEl,
       {
         x: -100,
         opacity: 0,
       },
       {
         scrollTrigger: {
           trigger: el,
           ...scrollTriggerOpt,
           // scrub: 5,
           // toggleActions: 'restart none',
         },
         opacity: 1,
         x: 0,
         duration: 1,
         ease: 'power1.out',
         stagger: 0.5,
       }
     )
   }, []);

    // about vission animation
    const aboutVission = useRef(null);
    useEffect(() => {
      const el = aboutVission.current;
      const listEl = el.querySelectorAll('.vission-content');
      gsap.fromTo(listEl,
        {
          x: 100,
          opacity: 0,
        },
        {
          scrollTrigger: {
            trigger: el,
            ...scrollTriggerOpt,
            // scrub: 5,
            // toggleActions: 'restart none',
          },
          opacity: 1,
          x: 0,
          duration: 1,
          ease: 'power1.out',
          stagger: 0.5,
        }
      )
    }, []);

    // goals animation
    const goals = useRef(null);
    useEffect(() => {
      const el = goals.current;
      const listEl = el.querySelectorAll('.value-box');
      gsap.fromTo(listEl,
        {
          y: 150,
          opacity: 0,
        },
        {
          scrollTrigger: {
            trigger: el,
            ...scrollTriggerOpt,
            // scrub: 5,
            // toggleActions: 'restart none',
          },
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power1.out',
          stagger: 0.5,
        }
      )
    }, []);

  return (
    <div>
      <section className='about-banner pb-0'>
        <div className='column'>
          <div className='columns'>
            <div className='column'>
              <img src={aboutUsBanner}></img>
            </div>
            <div className='column pl-6' ref={aboutBanner}>
              {/* <div className='is-uppercase has-text-weight-bold mb-3 has-text-grey-dark'>About us</div> */}
              <h1 className='mb-3 about-banner-content'>
              About us
              </h1>
              <p className='mb-3 is-size-5 about-banner-content'>
              Loansanthe is a financial consulting firm based in Bengaluru. We started in 2006 with a view of providing a holistic solution for personal loans for our clients. We are a well-known personal loan consultation agency in the city and boast over 8000 happy and satisfied customers.
              </p>
              <p className='mb-3 is-size-5 about-banner-content'>
              Based on your loan criteria, we do due diligence and come up with the most suitable bank and the optimum interest rate. We offer our undivided attention on our customers to ensure a seamless process.  
              </p>
              <button className="button is-primary is-normal" onClick={redirect}>Apply for Quick Loan</button>
            </div>
          </div>
        </div>
      </section>
      <section className='about-stats'>
        <div className='column'>
          <div className='columns'>
            <div className='column is-align-items-center is-justify-content-center'>
            <div className='is-flex'>
                <div className='block1-count'>
                  6
                </div>
                <div className='count-measure'>
                  + YEARS
                </div>
              </div>
              <div className='is-grey-text is-size-5'>
              EXPERIENCE
              </div>
            </div>
            <div className='column is-align-items-center is-justify-content-center'>
              <div className='is-flex'>
                <div className='block2-count'>
                5000
                </div>
                <div className='count-measure'>
                +
                </div>
              </div>
              <div className='is-grey-text text is-size-5'>
                SUCCESFUL LOANS
              </div>
            </div>
            <div className="column">
            <div className='is-flex'>
                <div className='block3-count'>
                % 
                </div>
                <div className='count-measure'>
                LOW
                </div>
              </div>
              <div className='is-grey-text text is-size-5'>
              INTEREST
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='about-mission'>
        <div className='column'>
          <div className='columns'>
            <div className='column pr-6 is-flex is-flex-direction-column is-justify-content-center' ref={aboutMission}>
              <div className='is-uppercase has-text-weight-bold mb-3 has-text-grey-light mission-content'>our mission</div>
              <h1 className='mb-3 has-text-white mission-content'>
                We’re on a mission to empower companies
              </h1>
              <div className='mb-3 has-text-grey-light is-size-5 mission-content'>
                To ensure that our clients get the loan to achieve their dreams, with minimal documentation and maximum benefits, with the apt bank for their end goal.
              </div>
            </div>
            <div className='column'>
            <img src={Mission} className='mission-img is-pulled-right' ></img>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='columns vision-columns'>
          <div className='column'>
          <img src={Vission} alt="Vission " title='Vission' className='mission-img' />
            </div>
            <div className='column pl-6 is-flex is-flex-direction-column is-justify-content-center' ref={aboutVission}>
              <h1 className='mb-3 has-text-white vission-content'>
              OUR Vision
              </h1>
              <div className='mb-3 has-text-grey-light is-size-5 vission-content'>
              To be the best loan provider in Bengaluru and make all our customers 100% satisfied.              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className='about-values'>
        <div className="container is-flex is-justify-content-center" ref={goals}>
          <div className="box-column1">
            <div className="value-box">
              <div className="is-size-5 has-text-info has-text-weight-semibold mb-2">01</div>
              <div className='is-size-3 has-text-weight-bold mb-2'>Credit Eligibility Criteria:</div>
              <div className='is-size-5 has-text-grey'>First, we will assess your criteria based on your age, profession, salary, and credit score.</div>
            </div>
            <div className="value-box">
              <div className="is-size-5 has-text-info has-text-weight-semibold mb-2">02</div>
              <div className='is-size-3 has-text-weight-bold mb-2 has-text-white'>Documents for submission:</div>
              <div className='is-size-5 has-text-grey-light'>Once you have passed our criteria, you will be required to submit the following documents for us to process your loan:</div>
            </div>
          </div>
          <div className="box-column2">
            <div className="value-box">
              <div className="is-size-5 has-text-info has-text-weight-semibold mb-2">03</div>
              <div className='is-size-3 has-text-weight-bold mb-2 has-text-white'>Verification and Approval:</div>
              <div className='is-size-5 has-text-grey-light'>When your application and documents pass the verification process, provided that no inconsistencies are found in them, we will be approving you for the loan sanction.
            </div>
            </div>
            <div className="value-box">
              <div className="is-size-5 has-text-info has-text-weight-semibold mb-2">04</div>
              <div className='is-size-3 has-text-weight-bold mb-2'>Loan Sanction and Disbursal:</div>
              <div className='is-size-5 has-text-grey'>Your loan amount will be sanctioned, and you will receive it in your bank account, along with our special cashback of 1% of your loan amount.</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutUs;