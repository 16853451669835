import React from "react";

const LoadinSpinner = () => {

  return (
    <div className="loading-spinner">
      <i className="fas fa-circle-notch fa-spin fa-2x" style={{color: '#0965e6'}}></i>
    </div>
  )
}

export default LoadinSpinner;