import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';



const privacyPolicy = () => {
  return (
    <div className="column">
      <div className="columns">

        <div className="column ">
          <div className="column">
            <p>
              Loansanthe.com, maintained by Sravi Fin Services("Company","We","Our","Us”) acknowledges and recognizes the importance of maintaining user privacy. The company is committed to maintaining the confidentiality, integrity, and security of all information of our users. This Privacy Policy describes how Loansanthe collects and handles certain information it may collect and/or receive from you via the use of our Website. This Privacy Policy applies to current and former visitors to our Website. By visiting and/or using our website/service, you are accepting and consenting to the practices described in this Privacy Policy.
            </p>
            <p>By using or continuing to use the Website, you agree to our use of your information (including sensitive personal information as defined under the Information Technology Act, applicable rules, notification, etc.) in accordance with this Privacy Policy, as may be amended from time to time by the Website in its discretion.
            </p>
          </div>
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  1. Controllers of Personal Information
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Your Personal information will be collected and stored securely by Loansanthe.com along with its parent company Sravi Fin Services (including its representatives, affiliates, and its business partners).
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  2. Personal Information That We Gather
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>The information we learn from customers helps us personalize and continually improve your experience at the Website.
                </p>
                <p>We receive and store any information you enter on our Website or give us in any other way. If you can choose not to provide certain information, then you might not be able to make use of many of our services and features.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  3. How do We Use The Information

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Loansanthe collects your information when you register for an account when you use its services, and visit its Website's pages. When you register with Loansanthe.com, you are asked for your first name, last name, state and city of residence, email address, date of birth, and sex. Once you register at the Website and sign in, you are not anonymous to us. Also, you are asked for your contact number during registration and may be sent SMS notifications about our services. Hence, by registering, you authorize Loansanthe (including its parent company Sravi Fin Services, business partners, and affiliates) to send texts and email alerts to you with your login details and any other service requirements, including promotional mail and SMS, even if you have registered yourself under DND or DNC or NCPR services. Your authorization shall be valid as long as your account is not deactivated with us.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  We use your information in order to:
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul>
                  <li>Facilitate and deliver services to you, process payments and your applications, and communicate with you about products, services, and promotional offers.
                  </li>
                  <li>Respond to queries or requests submitted by you, and resolve your grievances/issues/problems with any services supplied to you.
                  </li>
                  <li>Send you information about special promotions or offers. We might also tell you about new features or products/services. These might be our own offers/promotions or services.
                  </li>
                  <li>Use your information for internal analysis and to provide you with location-based services, such as advertising, search results, and other personalized content.
                  </li> <li>Use this information to improve our platform, prevent or detect fraud or abuses of our Website and enable third parties to carry out technical, logistical or other functions on our behalf. We may combine information we get from you with information about you we get from third parties.
                  </li> <li>Send you notices communications, and recommend services that might be of interest to you, update our records, and generally maintain your accounts with us, display content and customer reviews.
                  </li><li>As otherwise provided in this Privacy Policy.
                  </li>
                </ul>
                <p>Some features of this Website or our Services will require you to furnish your personally identifiable information as provided by you under your account section on our Website.
                </p>
                <p>You also agree and consent to us collecting, storing, processing, transferring and sharing information (including sensitive personal information) related to you with third parties or service providers for the purposes as set out in this Privacy Policy.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  4. Information Sharing and Disclosure

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Loansanthe will not sell or rent or otherwise disclose your information for commercial purposes to anyone in a way that is contrary to the commitments made and/or other than as set forth in this Privacy Policy. Notwithstanding the foregoing, we may share your information to an affiliate and/or business partner. Loansanthe.com may also share, sell, and/or transfer your personally identifiable information to any successor-in-interest as a result of a sale of any part of Loansanthe.com business or upon the merger, reorganization, or consolidation of it with another entity on the basis that it is not the surviving entity. For the purposes of this paragraph, "affiliate" means any person, company, or Loansanthe.com directly, or indirectly through one or more intermediaries, that controls, is controlled by, or is under common control with Loansanthe.com (The term "control," as used in the preceding sentence, shall mean with respect to any person, the possession, directly or indirectly, of the power, through the exercise of voting rights, contractual rights or otherwise, to direct or cause the direction of the management or policies of the controlled person. As used in this Privacy Policy, the term "Person" includes any natural person, corporation, partnership, limited liability company, trust, unincorporated association, or any other entity).</p>
                <p>We limit the collection and use of your personal information. We may make anonymous or aggregate personal information and disclose such data only in a non-personally identifiable manner. Such information does not identify you individually. Access to your Account information and any other personal identifiable information is strictly restricted and used only in accordance with specific internal procedures, and for the purposes set out in this Privacy Policy, in order to operate, develop or improve our services. We may use third-party service providers to enable you to provide with our services and we require such third parties to maintain the confidentiality of the information we provide to them, under our contracts with them.
                </p> <p>We may also share your information, without obtaining your prior written consent, with government agencies mandated under the law to obtain information for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences, or where disclosure is necessary for compliance of a legal obligation. You agree and consent for the Website to disclose your information, if so required, under applicable law.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  5. How we secure your Personal Information

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Loansanthe.com understands that the confidentiality, integrity, and availability of your information are vital to our business operations and our own success. We employ appropriate technical and organizational security measures at all times to protect the information we collect from you. We use multiple electronic, procedural, and physical security measures to protect against unauthorized or unlawful use or alteration of information, and against any accidental loss, destruction, or damage to information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security. Further, you are responsible for maintaining the confidentiality and security of your login id and password, and may not provide these credentials to any third party.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  6. What Is Your Control Over Your Personal Information That’s Collected And Used Online


                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>It's important to note that the information we use about you helps us provide you with products, services and experiences that benefit you. You have the ability to control how your non-personal information is collected and used online.
                </p>
                <p>You also have the ability to choose what personal information, including what sensitive personal information (i.e. your financial information) you provide to us. However, if you choose not to provide all of the information and data that is requested of you, we may not be able to provide you with the Services that you have subscribed to.
                </p>
                <p>You may, at any time while availing of our Services or otherwise, withdraw the consent given earlier to us to collect and use your sensitive personal data or information by writing to us at info@loansanthe.com. However, in the case of you withdrawing such consent, Loansanthe.com shall have the option to stop providing you the Services for which the information was sought. You will not be eligible for refund of any fees paid for any service in such an event and you agree that the Website shall not be liable to you for the same in any manner whatsoever.
                </p>
                <p>When you provide your contact details, we will use it to send you general notices or important news about your account, request your feedback or opinions and provide updates on special promotions and offers that might interest you.
                </p>
                <p>We believe you should be able to choose what kinds of information you receive via email/SMS. If you do not want to receive marketing materials by email/SMS, just write to us at info@loansanthe.com. Please note that it may take about a week to 10 days to process your request. Please keep in mind that we will continue to notify you by email /SMS/via phone calls regarding your services with us, even after you have opted out.
                </p>
                <p>You can review the information that you have provided to us by logging into your account at the Website and correct or amend any personal information or sensitive personal data or information to ensure that the information or data you provided us is accurate and/or not deficient. Loansanthe.com is not responsible for the authenticity of any personal information or sensitive personal data or information supplied to it by you or any third party.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  7. Changes to the Privacy Policy
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Loansanthe.com reserves the right to change this policy from time to time, at its sole discretion. We may update this privacy policy to reflect changes to our website functionality/information practices/ changes in legislation. We encourage you to periodically review the document. Checking the effective date below allows you to determine whether there have been changes since the last time you reviewed the statement.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  8. Grievances
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                In case you have any grievances or want to address any discrepancy with respect to the processing of any of the information/data you provided to Loansanthe.com, please contact our Proprietor. Name and contact details are provided below:
                <p>Mr. Sharavana.R</p>
                <p>No 13/2 , 2nd floor, CKC Garden, Lal Bagh Road,</p>
                <p>Bangalore - 560027.</p>
                <p>Mobile Number: 9035378777
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>

      </div>
    </div>


  )
}

export default privacyPolicy;